<!-- This component is responsible for selecting the project and folder to clone -->
<template>
    <div class="directoryClone mx-auto mt-5">
        <div class="clone-content mb-5">
            <div class="content-project">
                <p class="clone-desc">
                    Select project to clone from
                </p>
                <b-form-select
                    class="content-project-dropdown"
                    v-model="selectedProjectList"
                    :options="projectList"
                    text-field="ProjectName"
                    value-field="ProjectID"
                    @change="getDirectoriesByProject"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled
                            >Select Project</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </div>
            <div class="content-folder">
                <p class="clone-desc">
                    Select folders to duplicate
                </p>
                <ejs-dropdowntree
                    class="content-folder-dropdown"
                    id="dropdowntree"
                    ref="treeObj"
                    :fields="fields"
                    :showCheckBox="true"
                    :select="select"
                    cssClass="dropdowntree"
                ></ejs-dropdowntree>
            </div>
        </div>
        <div class="clone-buttons float-right">
            <div class="clone-buttons-cancel">
                <span class="cancelButton" @click="cancelClone">Cancel</span>
            </div>
            <div class="clone-buttons-save ">
                <b-button
                    rounded
                    class="buttonSelect"
                    :disabled="cloneDataResult.length === 0"
                    @click="saveClone"
                    >Save</b-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { projectService } from '../../services/project.service';
import { mapGetters, mapMutations } from 'vuex';
import { directoryService } from '../../services/directory.service';
import { detach } from '@syncfusion/ej2-base';
import { DropDownTreePlugin } from '@syncfusion/ej2-vue-dropdowns';

Vue.use(DropDownTreePlugin);
var selectedData = [];
export default {
    components: {},
    data() {
        return {
            fields: {
                dataSource: [],
                value: 'id',
                text: 'name',
                parentValue: 'pid',
                hasChildren: 'hasChild',
            },
            projectName: '',
            selectedProjectList: null,
            projectList: [],
            cloneDataResult: [],
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),

        getDirectoriesByProject() {
            let projectName = this.projectList.filter(
                (item) => item.ProjectID == this.selectedProjectList
            );
            this.projectName = projectName[0].ProjectName;
            directoryService
                .getDirectoryChild(this.selectedProjectList)
                .then((res) => {
                    this.projectDirectoryList = res.data;
                    var treeObj = document.getElementById('dropdowntree')
                        .ej2_instances[0];
                    treeObj.fields = {
                        dataSource: res.data,
                        value: 'id',
                        text: 'name',
                        parentValue: 'pid',
                        hasChildren: 'hasChild',
                    };
                    treeObj.dataBind();
                    const isTree = document.querySelector(
                        '#' + treeObj.element.id + '_tree'
                    );
                    if (isTree) {
                        const popupDiv = document.querySelector(
                            '#' + treeObj.element.id + '_popup'
                        );
                        detach(popupDiv ? popupDiv : isTree.parentElement);
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage:
                                'Something went wrong.Please try again later.',
                        });
                    }
                });
        },
        getNestedChildren(arr, parent) {
            var out = [];
            for (var i in arr) {
                if (arr[i].parentID == parent) {
                    arr[i].projectId = parseInt(this.ProjectID);
                    var children = this.getNestedChildren(arr, arr[i].id);

                    if (children.length) {
                        arr[i].children = children;
                    }
                    arr[i].id = Number(arr[i].id);
                    arr[i].parentID = Number(arr[i].parentID);
                    out.push(arr[i]);
                }
            }
            return out;
        },
        select: function(args) {
            if (args.isInteracted) {
                //Gets the corresponding original data
                var itemData = this.projectDirectoryList.find(
                    (e) => e.id.toString() === args.itemData.id.toString()
                );
                //add / remove the item in the selected data array based on selection
                if (args.action === 'select') {
                    selectedData.push(itemData);
                } else if (args.action === 'un-select') {
                    selectedData.splice(
                        selectedData.findIndex((e) => e.id === itemData.id),
                        1
                    );
                }
                //Get the CheckedNodes list from teh tree Objedt inside dropdowntree.
                var ddt1 = this.$refs['treeObj'].ej2Instances;
                var tree = ddt1.treeObj;
                var checkedNodes = tree.checkedNodes.slice();

                //Action on parent start

                //Gets the parentItem of the selectednode
                var parentItem = itemData.pid
                    ? this.projectDirectoryList.find(
                          (e) => e.id === itemData.pid
                      )
                    : null;
                //Loop continues till the top level parent
                while (parentItem) {
                    //Checks whether the parent is in checked list and adds it if not present

                    var parentid = parentItem.id;
                    var index = checkedNodes.indexOf(parentid.toString());
                    if (args.action === 'select' && index === -1) {
                        //Adds to the checked list if it's select action and parent is not in list
                        checkedNodes.push(parentid.toString());
                        selectedData.push(parentItem);
                    } else if (args.action === 'select' && index !== -1) {
                        //Breaks the loop if it's select action and parent is in list
                        break;
                    } else if (args.action === 'un-select' && index !== -1) {
                        //This If case can be ignored, if you don't want to unselect parent based on child, you can ignore this block
                        //Checks whether the parent has any other selected child it's un-select action and parent is in list
                        var childIndex = selectedData.findIndex(
                            (e) => e.pid === parentid
                        );
                        if (childIndex === -1) {
                            //removes the parent if there is no selected child
                            checkedNodes.splice(index, 1);
                            selectedData.splice(
                                selectedData.findIndex(
                                    (e) => e.id === parentid
                                ),
                                1
                            );
                        } else {
                            //breaks the loop if the parent has another selected child
                            break;
                        }
                    }
                    //Gets the next level parent if the loop is not broken.
                    parentItem = this.projectDirectoryList.find(
                        (e) => e.id === parentItem.pid
                    );
                }

                //Action on parent end
                //Action on Child start

                //Gets the child of the item
                var ChildItems = this.projectDirectoryList.filter(
                    (e) => e.pid === itemData.id
                );
                //loops if there is a child
                while (ChildItems.length !== 0) {
                    var subChilds = [];
                    ChildItems.forEach((element) => {
                        if (args.action === 'select') {
                            //if select action adds the child to the list
                            checkedNodes.push(element.id.toString());
                            selectedData.push(element);
                        } else {
                            //if un-select action , checks if the child present in the list and removes
                            var index = checkedNodes.indexOf(
                                element.id.toString()
                            );
                            if (index !== -1) {
                                checkedNodes.splice(index, 1);
                                selectedData.splice(
                                    selectedData.findIndex(
                                        (e) => e.id === element.id
                                    ),
                                    1
                                );
                            }
                        }
                        //Gets the children of the child and adds in the subchild array
                        var Children = this.projectDirectoryList.filter(
                            (e) => e.pid === element.id
                        );
                        subChilds = subChilds.concat(Children);
                    });
                    //after processing all child assign sub-childs to the child list and repeat the prcoess at next level child
                    ChildItems = subChilds;
                }

                //Action on Child end

                //assign the checkednodes list ot the treeObj of dropdowntree
                tree.checkedNodes = checkedNodes;
                tree.refresh();
            }
            var ddt = document.getElementById('dropdowntree').ej2_instances[0];

            var selection = ddt.treeObj.getAllCheckedNodes();

            var cloneData = [];
            this.cloneDataDirectory = [];
            for (var i = 0; i < selection.length; i++) {
                var parentdata = ddt.treeObj.getNode(selection[i]).parentID;

                let parentData = ddt.treeObj.getNode(parentdata);
                let filterNodeData = ddt.treeObj.getNode(selection[i]);
                let filterParentData = this.cloneDataDirectory.filter(
                    (item) => item.id == parentData.id
                );
                let filternodeData = this.cloneDataDirectory.filter(
                    (item) => item.id == filterNodeData.id
                );
                if (filterParentData.length == 0) {
                    this.cloneDataDirectory.push(
                        ddt.treeObj.getNode(parentdata)
                    );
                }
                if (filternodeData.length == 0) {
                    this.cloneDataDirectory.push(
                        ddt.treeObj.getNode(selection[i])
                    );
                }
            }
            this.cloneDataResult = this.getNestedChildren(
                this.cloneDataDirectory
            );
        },
        cancelClone() {
            this.projectDirectoryList = [];
            this.selectedProjectList = null;
            var treeObj = document.getElementById('dropdowntree')
                .ej2_instances[0];
            treeObj.fields.dataSource = '';
            treeObj.refresh();
        },
        saveClone() {
            directoryService
                .cloneSelectedDirectories(this.cloneDataResult)
                .then((res) => {
                    console.log(res.data);
                    if (res.data == 'CloneDirectory' || res.status === 200) {
                        this.setToastMessage({
                            showSuccess: true,
                            successToastMessage:
                                'Directory cloned successfully',
                        });

                        this.projectDirectoryList = [];
                        this.selectedProjectList = null;
                        var treeObj = document.getElementById('dropdowntree')
                            .ej2_instances[0];
                        treeObj.fields = {
                            dataSource: [],
                            value: 'id',
                            text: 'name',
                            parentValue: 'pid',
                            hasChildren: 'hasChild',
                        };
                        treeObj.dataBind();
                        const isTree = document.querySelector(
                            '#' + treeObj.element.id + '_tree'
                        );
                        if (isTree) {
                            const popupDiv = document.querySelector(
                                '#' + treeObj.element.id + '_popup'
                            );
                            detach(popupDiv ? popupDiv : isTree.parentElement);
                        }
                    }
                })

                .catch((err) => {
                    this.setToastMessage({
                        showFailure: true,
                        failureToastMessage:
                            'Something went wrong. This folder might already exist!',
                    });
                });
        },

        getDirectories() {
            projectService
                .getDirectories()
                .then((res) => (this.directories = res.data));
        },

        getProjectlist() {
            projectService.getAllWithGeo().then((res) => {
                this.allProjectsList = res.data;
                this.projectList = this.allProjectsList
                    .filter(
                        (item) => item.ProjectID !== parseInt(this.ProjectID)
                    )
                    .sort((a, b) => a.ProjectName.localeCompare(b.ProjectName));
            });
        },
    },
    created() {
        this.getDirectories();
        this.getProjectlist();
    },
    computed: { ...mapGetters('projects', { ProjectID: 'ProjectID' }) },
};
</script>
<style scoped>
.directoryClone {
    width: 75%;

    .clone-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .cancelButton {
        cursor: pointer;
        margin: 0;
    }

    .clone-buttons-save {
        text-align: right;
    }

    .clone-content {
        margin-top: 20px;
    }

    .clone-desc {
        margin-bottom: 10px;
    }

    .e-input-group {
        width: 100%;
        display: inline-flex;
        align-items: center;
        padding: 5px;
        background-color: #fff;
        border: 1px solid #ccc !important;
        border-radius: 5px;
    }

    .content-project select {
        padding: 10px;
    }

    .custom-select {
        background: #fff url('../../assets/arrow_down.svg') no-repeat right
            0.75rem center/8px 10px !important;
    }

    .e-control-wrapper {
        align-items: baseline;
    }
    .e-input-group-icon,
    .e-clear-icon {
        bottom: 8px !important;
    }
    .e-input-group,
    .content-project-dropdown {
        width: 100%;
        min-height: 51px;
        display: inline-flex;
        align-items: center;
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ced4da !important;
        border-radius: 5px;
    }
}
</style>
<style></style>
